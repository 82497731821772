// custom setup code goes here
$red-350: #C30000;
$pink-000: #f77ef1;
$pink-100: #f967f1;
$pink-200: #e94ee1;
$pink-300: #dd2cd4;

// override the default variables
$link-color: $red-350;
$btn-primary-color: $red-350;
