// utilities
.text-white {
  color: $white !important;
}

.bg-red-350 {
  background-color: $red-350 !important;
}

// logo
.site-logo__text {
  line-height: 2.5rem;
  margin-left: 3rem;
}

// slides
ul.slides {
  list-style: none;
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 1rem;

  li {
    width: 20%;
    list-style: none;

    &:before {
      content: '';
    }
  }
}

.event-participation {
  svg {
    height: 1em;
    width: 1em;
    vertical-align: text-bottom;
  }
}

// meetups
.meetup__description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


// pagination
.meetup__pagination {
  display: flex;
  justify-content: center;
  gap: 2rem;
  list-style: none;

  .meetup__pagination-item {
    &::before {
      content: '';
    }
  }

  .meetup__pagination-item--current {
    margin: auto 0.25rem;
  }
}

.meetup__card {
  &:hover {
    opacity: .8;
    background-image: linear-gradient(-90deg, #ebedf5 0%, rgba(235, 237, 245, 0.8) 80%, rgba(235, 237, 245, 0) 100%);
  }
}

.meetup__date {
  font-size: 80%;
  margin-left: .5rem;
}

.meetup__link {
  &:hover {
    opacity: .8;
    background-image: linear-gradient(-90deg, #ebedf5 0%, rgba(235, 237, 245, 0.8) 80%, rgba(235, 237, 245, 0) 100%);
  }
}

.meetup__footer-link {
  display: flex;
  justify-content: space-between;
}

// slack link
.slack-link__img {
  display: inline-block;
  vertical-align: middle;
}

.slack-link__text {
  display: inline-block;
  vertical-align: middle;
  text-transform: none !important;
}

// home
.home__latest-meetup-link {
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
}

.guest-profile,
.kanazawa-rb-profile {
  display: flex;
  flex-flow: column;
}

@media (map-get($media-queries, sm) <= width) {
  .guest-profile {
    flex-flow: row-reverse;

    .guest-image {
      flex-shrink: 0;
      margin-left: 1em;
    }
  }

  .kanazawa-rb-profile {
    flex-flow: row nowrap;

    .kanazawa-rb-logo {
      flex-shrink: 0;
      margin-right: 2em;
    }
  }
}

.event-links-wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  .event-report {
    font-weight: bold;
  }
}
